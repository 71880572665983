// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.card-value{
    font-size: 32px;
    font-weight: 700;
}

.card-title{
    font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,2CAA2C;AAC/C;;;AAGA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".card {\n    border-radius: 6px;\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n}\n\n\n.card-value{\n    font-size: 32px;\n    font-weight: 700;\n}\n\n.card-title{\n    font-size: 1.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
