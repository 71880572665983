// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .vici-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 6.4rem;
  padding: 0 4.8rem;
  position: relative;
}

.vici-logo {
  height: 4rem;
}

.left-menu {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: -250px;
  transition: left 0.3s ease;
  background-color: #333;
  color: white;
  z-index: 1000;
}

.left-menu.open {
  left: 0;
}

.toggle-button {
  background-color: #444;
  color: white;
  padding: 10px;
  cursor: pointer;
  width: 100%;
}

.menu-content {
  padding: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 10px 0;
  cursor: pointer;
  color: white;
}

li:hover {
  background-color: #555;
}

li a.active {
  color: #61CE70 !important;
  font-weight: bold;
}

li a.active:hover {
  background-color: transparent;
} */`, "",{"version":3,"sources":["webpack://./src/components/Header/style.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAgEG","sourcesContent":["/* .vici-header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #fff;\n  height: 6.4rem;\n  padding: 0 4.8rem;\n  position: relative;\n}\n\n.vici-logo {\n  height: 4rem;\n}\n\n.left-menu {\n  width: 250px;\n  height: 100%;\n  position: fixed;\n  top: 0;\n  left: -250px;\n  transition: left 0.3s ease;\n  background-color: #333;\n  color: white;\n  z-index: 1000;\n}\n\n.left-menu.open {\n  left: 0;\n}\n\n.toggle-button {\n  background-color: #444;\n  color: white;\n  padding: 10px;\n  cursor: pointer;\n  width: 100%;\n}\n\n.menu-content {\n  padding: 20px;\n}\n\nul {\n  list-style: none;\n  padding: 0;\n}\n\nli {\n  padding: 10px 0;\n  cursor: pointer;\n  color: white;\n}\n\nli:hover {\n  background-color: #555;\n}\n\nli a.active {\n  color: #61CE70 !important;\n  font-weight: bold;\n}\n\nli a.active:hover {\n  background-color: transparent;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
