import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ReactTabulator } from "react-tabulator";
import { useToasts } from "react-toast-notifications";
import { getListAppel } from "../../store/cdrSlice";
import statusCode from "../../utils/statusCode";
import Loader from "../../components/Loader";
import { simpleInput } from "../../components/tabulator/tabulator";

const Cdr = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const listAppel = useSelector((state) => state.listAppel);
  const { data, error, statusListAppel } = listAppel;
  const [dataRequest, setDataRequest] = useState([]);

  //Colonne react tabulator numero
  const colonneTrunk = [
    { title: "date", field: "calldate", formatter: simpleInput },
    { title: "source", field: "src", formatter: simpleInput },
    { title: "destination", field: "dst", formatter: simpleInput },
    { title: "durée (s)", field: "billsec", formatter: simpleInput },
    { title: "Status", field: "disposition", formatter: simpleInput },
    /*     { title: "Plus", width: 100, field: "", formatter: buttonAssignment },
     */
  ];
  useEffect(() => {
    if (!userInfo?.token) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    dispatch(getListAppel({ token: userInfo.token }));
  }, []);
  useEffect(() => {
    if (data && data?.list?.length > 0) {
      const newArray = data.list?.map((el) => {
        return { ...el };
      });
      setDataRequest(newArray);
    }
  }, [data]);
  return (
    <>
      <Container>
        <Row className="d-flex flex-wrap justify-content-between align-items-center">
          <Col className="col-12" style={{ marginLeft: "5px" }}>
            <h5 className="current-route text-capitalize">
              / Historique appels /
            </h5>
          </Col>
        </Row>
        <Row className="tabHistorique" style={{ marginTop: "20px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              padding: "0px 0px 0px 40px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span className="titreTabulator">
                Liste des appels -{" "}
                {dataRequest?.length ? dataRequest.length : "0"} appel(s)
              </span>
            </div>
            <div
              className="champRecherche"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  padding: "8px 12px 8px 12px",
                  backgroundColor: "rgba(226, 232, 240, 0.6)",
                  borderRadius: "8px",
                  height: "40px",
                  display: "flex",
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#818CF8"
                    style={{ width: "18px", height: "18px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  className="rechercheTabulator"
                  placeholder="Recherche"
                  onChange={(ev) => {}}
                />
              </span>
            </div>
          </div>
          {statusListAppel == statusCode.LOADING ? (
            <Loader />
          ) : (
            <>
              <ReactTabulator
                data={dataRequest ? dataRequest : []}
                columns={colonneTrunk}
                layout={"fitColumns"}
                options={{
                  locale: true,
                  pagination: "local",
                  paginationSize: 10,
                  langs: {
                    fr: {
                      pagination: {
                        first: "Premier",
                        first_title: "Première",
                        last: "Dernier",
                        last_title: "Dernière",
                        prev: "Précédent",
                        prev_title: "Précédente",
                        next: "Suivant",
                        next_title: "Page suivante",
                        page_size: "Taille de page",
                        page_title: "Afficher la page",
                        all: "Tous",
                      },
                    },
                  },
                }}
              />
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Cdr;
